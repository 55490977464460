import request from '@/utils/request'

export function orgList(parentId, isOnlyActive, isOrderByTime) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getOrgLazyTree`,
    method: 'get',
    params: { parentId, isOnlyActive, isOrderByTime }
  })
}

export function getOrgManagerDataPermission(isOnlyActive) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getOrgManagerDataPermission`,
    method: 'get',
    params: { isOnlyActive }
  })
}

export function getOrgManagerDataPermissionList(isOnlyActive, terminalFlag) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getOrgManagerDataPermissionList`,
    method: 'get',
    params: { isOnlyActive, terminalFlag }
  })
}

export function getOrgByName(rootIds, orgName, isOnlyActive) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getOrgByName`,
    method: 'post',
    data: { rootIds, orgName, isOnlyActive }
  })
}

export function getSuperiorOrgIds(rootIds, orgId) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getAllSuperiorOrgIds`,
    method: 'post',
    data: { rootIds, orgId }
  })
}

export function getOneNodeInfo(id) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/${id}`,
    method: 'get'
  })
}

export function getSystemRegions(parentId) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/systemRegion/getRegions`,
    method: 'get',
    params: { parentId }
  })
}
//获取地区树
export function findSystemregionTree() {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/systemRegion/findSystemregionTree`,
    method: 'post'
  })
}
export function saveOrg(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/saveOrgInfo`,
    method: 'post',
    data
  })
}

export function list(rootIds, isOnlyActive) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/list`,
    method: 'post',
    data: { rootIds, isOnlyActive }
  })
}

export function changeRanking(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/changeRanking`,
    method: 'post',
    data
  })
}

// 远程控件，获取组织
export function getRemoteOrg(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getRemoteOrg`,
    method: 'post',
    data
  })
}

// 远程控件，获取组织
export function getLoginOrgInfo(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/getLoginOrgInfo`,
    method: 'get',
    data
  })
}

// 查询当前登陆人数据权限下非供应商组织信息
export function queryOrgDataPermissionsExcludeSupplier(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/orgInfo/queryOrgDataPermissionsExcludeSupplier`,
    method: 'post',
    data
  })
}
