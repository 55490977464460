<!--
 * @Author: your name
 * @Date: 2020-09-23 09:37:37
 * @LastEditTime: 2020-10-19 15:06:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\pages\Home.vue
-->
<template>
  <div class="home">
    <el-alert
      v-if="isTobeExpiration"
      style="margin-bottom:10px;"
      :closable="false"
      type="warning"
      show-icon>
      <span slot="title" >
        <p style="text-align:left">即将到期！</p>
        <p style="margin-top:5px;">
          您的服务剩余{{this.expirationDays}}天即将到期,(过期时间：{{this.expirationTime}})，请尽快续费。
        </p>
      </span>
    </el-alert>
    <el-alert
      v-if="isExpiration"
      style="margin-bottom:5px;"
      :closable="false"
      type="error"
      show-icon>
      <span slot="title">
        <p style="text-align:left">服务已到期！</p>
        <p style="margin-top:5px;">
          您的服务已到期，请尽快续费。
        </p>
      </span>
    </el-alert>
    <h1>欢迎进入都测企业CT</h1>
    <!-- <div class="home-t clearfix">
      <div class="t-left fl">
        <div class="title">待处理</div>
        <div class="wrap">
          <div class="item">
            <div class="item-top">
              <span>待付款</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="item-bottom">0</div>
          </div>
          <div class="item">
            <div class="item-top">
              <span>待发货</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="item-bottom">0</div>
          </div>
          <div class="item">
            <div class="item-top">
              <span>售后</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="item-bottom">0</div>
          </div>
        </div>
      </div>
      <div class="t-right fr">
        <div class="title">商品管理</div>
        <div class="wrap">
          <div class="item">
            <div class="item-top">
              <span>销售中</span>
            </div>
            <div class="item-bottom">0</div>
          </div>
          <div class="item">
            <div class="item-top">
              <span>待上架</span>
            </div>
            <div class="item-bottom">0</div>
          </div>
          <div class="item">
            <div class="item-top">
              <span>新增商品</span>
            </div>
            <div class="item-bottom add-product">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-b">
      <div class="title">数据趋势</div>

      <div class="b-row clearfix">
        <div class="row-title fl">指标：</div>
         <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="b-row clearfix" style="margin-bottom:0">
        <div class="row-title fl">时间：</div>
        <el-date-picker
      v-model="value1"
      type="daterange"
      range-separator="~"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
      </div>

      <div id="charts" style="width:90%;height:50%"></div>
    </div> -->
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import { getOneNodeInfo } from '@/api/orgInfo/orgInfo'
export default {
  data(){
    return{
      options : [],
      value1 : '',
      value : '',
      expirationTime:'',
      isExpiration:false,
      isTobeExpiration:false,
      expirationDays:''

    }
  },
  methods: {
    drawChart() {
      let myChart = this.$echarts.init(document.getElementById("charts"));
      let options = {
        color : ['#36C460','#1890FF'],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"],
          axisPointer: {
              label: {
                  formatter: function (params) {
                      return `${parseTime(new Date(),'{y}-{m}-{d}')} ` + params.value
                  }
              }
          },
        },
        yAxis: {
          type: "value",
          axisLine : {
            show:false,
          },
           axisTick : {
             show:false
           },
            splitLine : {
              lineStyle : {
                type : 'dashed'
              }
            }
        },
        series: [
          {
            name: "绿色",
            type: "line",
            stack: "总量",
            lineStyle : {
              color:'#36C460'
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "蓝色",
            type: "line",
            stack: "总量",
            lineStyle : {
              color:'#1890FF'
            },
            data: [220, 182, 191, 234, 290, 330, 310],
          }
        ],
      };
      myChart.setOption(options);
    },
    getDaysBetween(dateString1,dateString2){
        var  startDate = Date.parse(dateString1);
        var  endDate = Date.parse(dateString2);
        var  days= parseInt(Math.abs(endDate - startDate)/(1*24*60*60*1000));
        if (startDate>endDate){
          this.isExpiration = true
          return days;
        }
        if (days < 30){
          this.isTobeExpiration = true
          return  days;
        }
        return days
    },
    checkIsExprise(){
        // 查询是否过期
        var date = new Date();
        var nowMonth = date.getMonth() + 1;
        var strDate = date.getDate();
        var seperator = "-";
        var d1 = date.getFullYear() + seperator + nowMonth + seperator + strDate;
        var d2 = new Date(this.expirationTime)
        this.getDaysBetween(d1,d2)
        this.expirationDays = this.getDaysBetween(d1,d2)
        console.log(this.getDaysBetween(d1,d2),'this.getDaysBetween(d1,d2)')
    }
  },
  async mounted() {
    // 根据组织查询合同到期时间
    const res = await this.$store.dispatch("GetUserInfo");
    const orgId = res.data.data.orgList[0].id;
    
    const response = await getOneNodeInfo(orgId);
    if (response.data.data) {
      const data = response.data.data;
      if (data.expiresTime) {
        this.expirationTime = data.expiresTime.split(' ')[0];
        this.checkIsExprise();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  text-align: center;
  position: relative;
  z-index: 10;
  background-color: white;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    line-height: 80px;
    padding-left: 30px;
  }
  .home-t {
    .t-left {
      width: 48%;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      height: 24vh;
      box-sizing: border-box;
      .wrap {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        .item {
          text-align: center;
          flex: 1;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 50%;
            background-color: #eee;
            right: 0;
            top: 25%;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          .item-top {
            font-size: 14px;
            line-height: 30px;
            cursor: pointer;
          }
          .item-bottom {
            font-size: 22px;
            font-weight: bold;
            line-height: 60px;
          }
        }
      }
    }
    .t-right {
      width: 48%;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      padding-bottom: 80px;
      height: 24vh;
      box-sizing: border-box;
      .wrap {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        .item {
          text-align: center;
          flex: 1;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 50%;
            background-color: #eee;
            right: 0;
            top: 25%;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          .item-top {
            font-size: 14px;
            line-height: 30px;
            cursor: pointer;
          }
          .item-bottom {
            font-size: 22px;
            font-weight: bold;
            line-height: 60px;
          }
        }
        .add-product {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          margin: 10px auto 0;
          background-color: #ffefec;
          text-align: center;
          line-height: 40px;
          color: #ff7158;
          i {
            line-height: 40px;
            font-size: 20px;
            vertical-align: top;
            font-weight: bold;
          }
        }
      }
    }
  }
  .home-b {
    .title{
      line-height: 80px;
    }
    margin-top: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    height: 54vh;

    .b-row{
      margin-bottom: 12px;
      .row-title{
        margin-left: 30px;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.home{
  /deep/.el-alert__icon{
    margin-top:-21px!important;
  }
}

</style>
